(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/layout/assets/javascripts/breakpoints.js') >= 0) return;  svs.modules.push('/components/tipsen/layout/assets/javascripts/breakpoints.js');
"use strict";

const tipsenBreakpoints = {
  TIPSEN_BREAKPOINT_SMALL: 413,
  TIPSEN_BREAKPOINT_SUPERSMALL: 375,
  TIPSEN_BREAKPOINT_ULTRASMALL: 320
};
const toExport = {
  tipsenBreakpoints
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.tipsen.breakpoints', toExport);
}

 })(window);